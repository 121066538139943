<template>
	<div class="buscador-section" :style="backgroundStyle">
		<div class="content">
			<div class="image-container">
				<img :src="skiMountain" alt="mountain logo" />
			</div>
			<div class="text-container">
				<div class="titulo">{{ $t('home.buscadorSection.title') }}</div>
				<div class="subtitulo">
					{{ $t('home.buscadorSection.subtitle') }}
				</div>
				<button class="btn btn-primary" @click="goToBuscador">
					<i class="fas fa-search"></i> {{ $t('home.buscadorSection.button') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useRouter } from 'vue-router';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { computed } from 'vue';
	import { Buscador } from '@/router/RouterNames';
	import skiMountain from '@/assets/home/skiMountain.svg';

	const store_buscador = buscadorStore();
	store_buscador.setFiltros();

	const filtrosSeleccionadosBuscador = computed(() => store_buscador.filtrosSeleccionadosBuscador);
	const router = useRouter();

	const goToBuscador = () => {
		const destino = filtrosSeleccionadosBuscador.value.destino || null;
		const fechasAlojamiento = filtrosSeleccionadosBuscador.value.fechasAlojamiento || [];
		const fechasForfait = filtrosSeleccionadosBuscador.value.fechasForfait || [];
		const habitaciones = filtrosSeleccionadosBuscador.value.habitaciones || [];

		router.push({
			name: Buscador,
			query: {
				destino: JSON.stringify(destino),
				fechasAlojamiento: JSON.stringify(fechasAlojamiento),
				fechasForfait: JSON.stringify(fechasForfait),
				habitaciones: JSON.stringify(habitaciones),
			},
		});
	};
</script>

<style scoped lang="scss">
	.buscador-section {
		position: relative;
		width: 100%;
		padding: 30px 0 50px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
	}

	.image-container {
		margin-right: 50px;
	}

	.image-container img {
		max-width: 600px;
		height: auto;
	}

	.text-container {
		max-width: 600px;
		padding-right: 70px;
		text-align: center;
	}

	.titulo {
		font-size: 28px;
		font-weight: 700;
		color: var(--bs-primary);
		margin-bottom: 15px;
	}

	.subtitulo {
		font-size: 16px;
		color: var(--bs-primary);
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 20px;
	}

	@media (max-width: 1024px) {
		.buscador-section {
			flex-direction: column;
			align-items: center;
			margin-top: 30px;
		}

		.content {
			flex-direction: column-reverse;
			align-items: center;
			text-align: center;
		}

		.image-container {
			margin-right: 0;
			margin-bottom: 30px;
		}

		.image-container img {
			max-width: 100%;
			height: auto;
		}

		.text-container {
			padding-right: 0;
			margin-bottom: 20px;
		}
	}
</style>
