<template>
	<div class="section-reservar mt-5">
		<div class="clip-path-wrapper">
			<div class="background-clip"></div>

			<div class="video-container text-center">
				<iframe
					width="100%"
					height="692px"
					src="https://www.youtube.com/embed/F53xBzIKMMM"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen></iframe>
			</div>

			<div class="image-text-section container-aligned">
				<div class="row align-items-center">
					<div class="col-lg-6 text-left d-flex justify-content-start">
						<div class="text-block">
							<div class="text-eat-sleep">{{ $t('home.porqueReservar.eatSleep') }}</div>
							<div class="text-ski-party ms-5">{{ $t('home.porqueReservar.skiParty') }}</div>
							<div class="text-repeat">{{ $t('home.porqueReservar.repeat') }}</div>
						</div>
					</div>

					<div class="col-lg-6 d-flex justify-content-end">
						<div>
							<p class="description">
								{{ $t('home.porqueReservar.texto1') }}
								<strong>Andorra, Formigal</strong> o <strong>Sierra Nevada</strong>
								{{ $t('home.porqueReservar.texto2') }}
							</p>
							<p class="description mt-4">
								{{ $t('home.porqueReservar.texto3') }}
								<strong>{{ $t('home.porqueReservar.alojamiento') }}</strong
								>, {{ $t('home.porqueReservar.material') }}
								{{ $t('home.porqueReservar.texto4') }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-aligned fondo-blanco pb-5">
			<div class="mt-4 mb-3 text-primary fs-2 fw-bold text-left">{{ $t('home.porqueReservar.title') }}</div>
			<div class="row d-flex flex-wrap reservar-content">
				<div
					v-for="motivo in textoPorqueReservar"
					:key="motivo.title"
					class="col-sm-6 col-lg-3 text-center cards-mobile">
					<div class="reservar-card">
						<div class="row">
							<div class="col-4 col-md-12 p-0">
								<img :src="motivo.imagenSrc" :alt="motivo.altText" :width="motivo.width" :height="motivo.height" />
							</div>
							<div class="col-8 col-md-12">
								<p class="titulo-card text-primary fw-bold">{{ motivo.title }}</p>
								<p>{{ motivo.description }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';

	const { t } = useI18n();
	const isMobile = computed(() => window.innerWidth <= 900);

	const medida = computed(() => (isMobile.value ? 90 : 150));

	const textoPorqueReservar = computed(() => [
		{
			title: t('porqueReservar[0].icono'),
			description: t('porqueReservar[0].descripcion'),
			imagenSrc: require('@/assets/categorias/Pago2.svg'),
			altText: t('porqueReservar[0].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar[1].icono'),
			description: t('porqueReservar[1].descripcion'),
			imagenSrc: require('@/assets/categorias/Atencion2.svg'),
			altText: t('porqueReservar[1].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar[2].icono'),
			description: t('porqueReservar[2].descripcion'),
			imagenSrc: require('@/assets/categorias/Flexibilidad.svg'),
			altText: t('porqueReservar[2].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar[3].icono'),
			description: t('porqueReservar[3].descripcion'),
			imagenSrc: require('@/assets/categorias/Ocio.svg'),
			altText: t('porqueReservar[3].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
	]);
</script>

<style lang="scss" scoped>
	.section-reservar {
		position: relative;
		z-index: 1;

		.clip-path-wrapper {
			position: relative;

			.background-clip {
				background: var(--bs-secondary);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				clip-path: polygon(
					0% 0%,
					100% 39.502%,
					0% 39.502%,
					0% 0%,
					0% 39.502%,
					0% 63.479%,
					0% 100%,
					100% 100%,
					100% 63.479%,
					100% 39.502%,
					0% 39.502%
				);
				z-index: -1;
			}

			@media (max-width: 1024px) {
				.background-clip {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
				}
			}
		}

		.container-aligned {
			padding: 0 90px;
			margin: 0 auto;
		}

		.container-aligned {
			padding: 20px 90px;
			background-color: var(--bs-secondary);
		}

		.video-container {
			width: 100%;
			text-align: center;
			padding: 0 90px;
			position: relative;
			margin-top: 170px;
			top: -130px;
			z-index: 2;
		}

		.image-text-section {
			margin-top: -80px;
		}

		.text-block {
			text-align: left;

			.text-eat-sleep,
			.text-ski-party,
			.text-repeat {
				margin-bottom: 0px;
				line-height: 1.1;
			}
		}

		.text-eat-sleep {
			color: var(--bs-primary);
			font-family: Montserrat;
			font-style: italic;
			font-size: 64px;
			font-style: italic;
			font-weight: 800;
			text-transform: uppercase;
			text-align: left;
		}

		.text-ski-party {
			-webkit-text-stroke-width: 2px;
			-webkit-text-stroke-color: var(--bs-primary);
			font-family: Montserrat;
			font-size: 64px;
			font-style: italic;
			font-weight: 800;
			letter-spacing: 2px;
			text-transform: uppercase;
			text-align: left;
		}

		.text-repeat {
			color: var(--Blanco, #fff);
			font-family: Montserrat;
			font-size: 64px;
			font-style: italic;
			font-weight: 800;
			letter-spacing: 0.18px;
			text-transform: uppercase;
			text-align: left;
		}

		.description {
			font-family: Montserrat;
			font-size: 16px;
			font-weight: 500;
			color: var(--bs-primary);
			line-height: normal;
			letter-spacing: 0.18px;
			margin: 10px 0;
		}

		.titulo-card {
			text-align: center;
		}

		.reservar-card {
			border-radius: 16px;
			background: #ffffff;
			box-shadow: 0px 0px 1px 0px rgba(var(--bs-primary-rgb), 0.4), 2px 4px 5px 0px rgba(var(--bs-primary-rgb), 0.1),
				4px 6px 20px 0px rgba(var(--bs-primary-rgb), 0.1);
			padding: 20px;
			margin-bottom: 20px;
			transition: transform 0.3s ease-in-out;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}

		.reservar-card:hover {
			transform: translateY(-10px);
		}

		@media (max-width: 1024px) {
			.text-block,
			.description {
				text-align: center;
			}

			.image-text-section {
				margin-top: 0;
			}

			.text-eat-sleep {
				font-size: 38px;
				font-style: italic;
				font-weight: 700;
			}

			.text-block {
				margin-top: -50px;
				margin-bottom: 20px;
			}

			.text-ski-party {
				-webkit-text-stroke-width: 2px;
				-webkit-text-stroke-color: var(--bs-primary);
				font-family: Montserrat;
				font-size: 38px;
				font-style: italic;
				font-weight: 700;
				letter-spacing: 2px;
				text-transform: uppercase;
				text-align: left;
			}

			.text-repeat {
				font-size: 38px;
				font-style: italic;
				font-weight: 700;
			}

			.description {
				font-size: 12px;
				font-weight: 500;
				color: var(--bs-primary);
				letter-spacing: 0.18px;
				text-align: left;
			}

			.container-aligned {
				padding: 20px 30px;
			}

			.video-container {
				width: 100%;
				text-align: center;
				padding: 0 30px;
				position: relative;
				margin-top: 100px;
				top: -90px;
				z-index: 2;
			}

			iframe {
				border: 0;
				height: 50vh;
			}

			.reservar-card {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 15px;
				gap: 15px;
			}

			.reservar-card .col-4 {
				flex: 0 0 30%;
				display: flex;
				justify-content: center;
			}

			.reservar-card .col-8 {
				flex: 0 0 70%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				text-align: left;
			}

			.reservar-card img {
				max-width: 100%;
				padding-left: 10px;
				height: auto;
			}

			.reservar-content {
				gap: 15px;
			}

			.titulo-card {
				text-align: left;
			}

			.fondo-blanco {
				background-color: white;
			}

			.cards-mobile {
				width: 100%;
			}
		}
	}
</style>
