export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])}
  },
  "header": {
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitari"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scuole"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratori"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglie"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi Privati"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinazioni"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazioni"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benvenuto a ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi dati"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
      "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrare"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze su misura"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinazione"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove volete andare?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando potete andare"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Alloggio"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando vuoi andare"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Skipàss"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date del Skipass"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanze"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli le stanze"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Gruppo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da dove venite?"])}
    }
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
    "restablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai esaurito l'alloggio"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila i seguenti dati e, se ci sono stanze disponibili, ti avviseremo"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIVI L'ESPERIENZA COMPLETA"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alle nostre esperienze con i tuoi amici e scopri una comunità di viaggiatori come te. Seleziona una destinazione, le date in cui vorresti viaggiare e la tua città di partenza. Cosa aspetti a unirti alle migliori esperienze sulla neve?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigliatore di esperienze"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando viaggiare"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NON LASCIARTI RACCONTARE"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alle nostre esperienze con i tuoi amici e scopri una comunità di viaggiatori come te. Seleziona una destinazione, le date in cui vorresti viaggiare e la tua città di partenza. Cosa aspetti a unirti alle migliori esperienze sulla neve?"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea la tua esperienza su misura"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri viaggi di gruppo non fanno per te? Usa il nostro motore di ricerca per configurare il viaggio completamente su misura."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al motore di ricerca"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Ski&Night?"])},
      "eatSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANGIA, DORMI"])},
      "skiParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIA, FESTEGGIA..."])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIPETI"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza il tuo viaggio sulla neve verso qualsiasi destinazione in Europa."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sono le destinazioni perfette per godersi la neve e il miglior divertimento notturno."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Ski&Night ti offriamo un'esperienza unica nelle migliori destinazioni, in un vero paradiso naturale. Ti offriamo"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" e l'accesso ai festival così che tu possa solo preoccuparti di divertirti."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggio, lezioni"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noleggio attrezzatura"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le scuole"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai tra i 5 e i 17 anni e vuoi sfruttare al massimo i giorni di sci, questo è il tuo viaggio. Migliora la tua tecnica e goditi la natura, noi ti forniamo il trasporto, l'alloggio e gli istruttori."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le famiglie"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti offriamo alloggi comodi per viaggiare sulla neve con la tua famiglia. Inoltre, puoi prenotare lezioni di sci per i bambini e molte altre attività adatte alle tue esigenze."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per universitari"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il tuo viaggio se vuoi incontrare persone della tua età ai migliori festival e après-ski. Ti senti pronto per vivere un'esperienza indimenticabile sulla neve?"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per lavoratori"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggiare con i tuoi colleghi è molto facile. Se hai più di 25 anni, unisciti ai nostri viaggi organizzati per incontrare persone come te, in cui lo sci e il divertimento notturno ti sorprenderanno."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota un viaggio esclusivo nelle migliori strutture sulla neve, arricchendo le giornate di sci con esperienze uniche sulla neve: gastronomia, relax, sport estremi o esperienze culturali."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, il nostro festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ski&Night abbiamo creato l'esperienza Winter Snow Festival, il nostro festival sulla neve in cui puoi goderti i migliori DJ e musicisti del momento in mezzo alle montagne."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest'anno festeggiamo la 4ª edizione del WSF, un'esperienza unica sulla neve che puoi goderti in Andorra insieme a oltre 5.000 persone come te."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta i nostri festival"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I migliori après ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo specialisti nell'organizzazione delle migliori feste sulla neve. Dopo una giornata sulla neve, puoi goderti della buona musica dei nostri DJ in un ambiente incomparabile. Ecco una selezione degli après ski più esclusivi:"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutti"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori offerte"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori stazioni sciistiche"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizziamo le migliori gite sulla neve a Formigal, Grandvalira e Sierra Nevada, tra altre stazioni. Dai un'occhiata alle stazioni in cui organizziamo viaggi e scegli quella che fa per te."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra Tutti"])}
    },
    "form": {
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il check-in"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "personalInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione personale"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome di battesimo"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di emissione della carta d'identità"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bentornati"])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email per accedere o registrarti"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con un altro account"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma email"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 8 caratteri"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 1 lettera maiuscola"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 1 numero"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password attuale"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Password"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la nuova password"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Postale"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genere"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschio"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femmina"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazionalità"])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno e Orario di Arrivo"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di partenza"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di nascita"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno di arrivo"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di arrivo"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronte e retro della carta d'identità caricata"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noleggio attrezzatura"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono indicativi; in negozio puoi provare a cambiarlo con quello che più ti si addice"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Height"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza (kg)"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'altezza"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura del piede (EU)"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la dimensione del piede"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email commerciali attive"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi raccontarci qualcosa di più su di te?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivere qui"])},
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte anteriore della carta d'identità"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere un PDF in formato PNG, JPG o GIF"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Documento"])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retro della carta d'identità"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come hai saputo di noi?"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vuoi puoi allegare il tuo CV"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error validating password, check credentials."])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante la convalida della password, controlla le credenziali"])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto l'invio di aggiornamenti e comunicazioni commerciali."])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non voglio ricevere notizie e comunicazioni commerciali."])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua password per accedere"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve soddisfare tutti i requisiti di sicurezza."])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento d'identità"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per una password più sicura, ti consigliamo di utilizzare una combinazione di lettere maiuscole e minuscole, numeri e caratteri speciali come *, %, !."])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi o registrati per prenotare"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato con successo"])}
      },
      "TripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche del Viaggio"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da dove stai viaggiando?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quante persone siete?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con chi viaggi?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amici"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglia"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleghi di lavoro"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date del viaggio"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quanti giorni vuoi sciare?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove vuoi andare?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna preferenza, fammi una raccomandazione!"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono interessato a"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomia"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festa e atmosfera"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax e spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività sulla neve"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove preferiresti alloggiare?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ho preferenze"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dalle piste?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio essere vicino alle piste"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mi dispiace spostarmi"])}
        }
      },
      "Thanks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti contatteremo entro 48 ore!"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per le informazioni. Presto riceverai notizie."])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e accetto la"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e accetto le"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e il"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla Privacy"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sui posti liberi"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di Cancellazione"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di Alloggio a Piena Occupazione"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione della Prenotazione"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni e spese di cancellazione"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le condizioni di prenotazione per il viaggio sono le seguenti:"])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il cliente annulla il viaggio, dopo la formalizzazione del deposito richiesto per ritenere la prenotazione RIMBORSABILE come definitiva, dovrà pagare le seguenti somme:"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il cliente annulla il viaggio, dopo la formalizzazione del deposito richiesto per ritenere la prenotazione NON RIMBORSABILE come definitiva, dovrà pagare le seguenti somme:"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il ", _interpolate(_named("porcentaje")), "% se avviene tra ", _interpolate(_named("fechaInicio")), " e prima del ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il 100% se è entro 15 giorni naturali prima della partenza."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione del viaggio non verrà rimborsata in nessun caso."])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi anche effettuare un cambio di titolare della prenotazione in modo che un compagno ti sostituisca, questo cambio potrebbe comportare un costo."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I costi di gestione associati alle modifiche alla prenotazione o al mancato rispetto dei termini di pagamento non saranno rimborsati in nessun caso."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dal ", _interpolate(_named("fechaInicio")), " al ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I supplementi e le spese associati a modifiche della prenotazione o dovuti al mancato rispetto dei termini e delle tariffe di pagamento non saranno in nessun caso rimborsabili."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puoi modificare gratuitamente la tua prenotazione fino al giorno ", _interpolate(_named("fecha")), " nella sezione Le mie prenotazioni del tuo profilo."])}
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor e collaboratori"])}
  },
  "modal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torneremo presto!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo messo da parte gli sci per questa stagione, ma stiamo lavorando per offrirti le migliori esperienze sulla neve."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri ottenere uno sconto di 10€ e conoscere le nostre offerte, lascia la tua email qui:"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "rrss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguici"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte per Gruppi"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le scuole"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le famiglie"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per universitari"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le aziende"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte per lo Sci"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Ponte di Dicembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Natale"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Capodanno"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Settimana Santa"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre offerte"])}
    },
    "terminosCondiciones": {
      "orgTecJuridica": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. ORGANIZZAZIONE TECNICA E REGOLAMENTAZIONE GIURIDICA"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In conformità con la Legge 34/2002, dell'11 luglio, sui servizi della società dell'informazione e di commercio elettronico (LSSI), si informa che il proprietario del sito web è Travel&Night S.L. (di seguito, TRAVEL&NIGHT) con sede in C/ Gloria Fuertes, 4 5B, 28049, Madrid, Madrid, con numero di C.I.F. B-87814125, registrato nel Registro Mercantile della Comunità di Madrid."])}
      },
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Legali di Vendita dei Servizi di TRAVEL&NIGHT S.L. - Partita IVA B87814125 L'acquisto di questo biglietto comporta l'accettazione delle condizioni legali, della politica sulla privacy e dei cookie, che puoi consultare sul sito di acquisto. Questo buono è revocabile in qualsiasi momento dall'organizzatore offrendo il rimborso dell'importo pagato. L'organizzazione stabilisce le proprie condizioni legali per l'accesso e la vendita dei servizi, che dovresti consultare sui loro media o richiedere esplicitamente a loro. Per qualsiasi reclamo relativo all'organizzazione dell'evento o dell'esperienza, il titolare di questo buono deve rivolgersi all'organizzatore. Non sono ammessi cambi o rimborsi, eccetto quelli autorizzati dall'organizzatore. L'organizzazione si riserva il diritto di ammissione e può negare o espellere il godimento di uno qualsiasi dei servizi che fanno parte dell'esperienza. TRAVEL&NIGHT non si assume alcuna responsabilità per problemi derivanti dalla contraffazione, duplicazione o vendita illegale di questo biglietto. In caso di rimborsi, l'organizzatore si riserva il diritto di non restituire le spese di gestione poiché queste sono state correttamente effettuate dalla piattaforma."])}
      },
      "inscripciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. ISCRIZIONI"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le condizioni di iscrizione a un'esperienza saranno dettagliate nel processo di prenotazione della stessa e prevarranno su qualsiasi altra condizione. In assenza di queste, si prenderanno di default: L'iscrizione del cliente al viaggio e la considerazione della prenotazione come confermata richiedono il pagamento di una somma equivalente al 30% del prezzo totale del viaggio. Il restante 70% deve essere pagato, al massimo, 21 giorni calendario prima della partenza del viaggio."])}
      },
      "condicionContractual": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. CONDIZIONE CONTRATTUALE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La realizzazione del viaggio in autobus è condizionata all'iscrizione di un minimo di 40 persone. Nel caso in cui il gruppo non si formi, l'agenzia può annullare il viaggio senza diritto a indennizzo per il cliente, purché l'agenzia lo comunichi entro un massimo di 5 giorni calendario prima della data prevista di partenza."])}
      },
      "cancelaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. CANCELLAZIONI"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Le prenotazioni del browser web, se esistenti, avranno le loro condizioni particolari accettate dal cliente al momento dell'acquisto."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Le condizioni di cancellazione delle prenotazioni per esperienze di gruppo saranno dettagliate nel processo di prenotazione della stessa e prevarranno su qualsiasi altra condizione. In assenza, si prenderanno di default le seguenti:"])},
        "cancelacionGratuita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non si accetta la cancellazione gratuita in nessun caso. Le condizioni saranno le seguenti:"])},
        "noReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- La prenotazione non è rimborsabile in nessun caso."])},
        "25%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Il 25% se la cancellazione avviene tra 45 e 30 giorni calendario prima della partenza."])},
        "50%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Il 50% se avviene tra 30 e 21 giorni calendario prima della partenza."])},
        "100%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Il 100% se è entro 20 giorni calendario prima della partenza."])},
        "derechoReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente non ha diritto a nessun rimborso del costo totale dell'alloggio."])},
        "modifReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Le spese di gestione associate a modifiche nella prenotazione o per il mancato rispetto dei tempi di pagamento della stessa non saranno rimborsabili in nessun caso."])}
      },
      "modificaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. MODIFICHE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualsiasi modifica o cambiamento può essere soggetto a spese di gestione associate allo stesso a seconda del servizio e della tempistica con cui è richiesto."])}
      },
      "precioViaje": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. PREZZO DEL VIAGGIO"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prezzo del viaggio offerto su questo sito include l'imposta sul valore aggiunto quando applicabile, ma non include nessun tipo di assicurazione né le tasse degli appartamenti. I prezzi rimangono validi fino a comunicazione contraria. Il prezzo può essere rivisto fino a 20 giorni calendario prima della data di partenza nel caso ci siano variazioni nei costi dei trasporti (incluso il costo del carburante), nelle tasse e nelle imposte relative a determinati servizi (come l'atterraggio, l'imbarco o lo sbarco in porti e aeroporti) e nel tipo di cambio applicato al viaggio. Di fronte alla variazione del prezzo, il cliente può accettare il supplemento o desistere dal servizio che è stato modificato. La Mezza Pensione, se contrattata, consiste in colazione e cena, le bevande a cena e gli extra di qualsiasi tipo NON sono inclusi."])}
      },
      "establecimientosAlojamiento": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. STRUTTURE DI ALLOGGIO"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto e la qualità dei servizi forniti dalla struttura di alloggio turistico sono determinati dalla categoria turistica locale specificata in questo opuscolo. TRAVEL&NIGHT si riserva il diritto di cambiare una certa struttura purché sia mantenuta la stessa categoria contrattata. Qualsiasi cambiamento per motivi esterni o operativi, purché sia mantenuta la qualità precedentemente menzionata, non dovrà necessariamente comportare un costo di cancellazione gratuita per il cliente. Nel caso di alloggio in appartamento, il check-in sarà dalle 17:00 e il check-out prima delle 11:00. I bambini che hanno un posto gratuito in hotel avranno solo l'alloggio, e le spese che ne derivano saranno pagate direttamente all'hotel dai clienti."])}
      },
      "documentacion": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. DOCUMENTAZIONE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente è obbligato a portare sempre con sé il documento d'identità nazionale o il passaporto originali e validi. I minori di 18 anni devono portare un documento che li autorizzi a viaggiare, firmato da entrambi i genitori, dal genitore che ha la custodia (in caso di matrimoni separati o divorziati) o dal tutore. Questa autorizzazione può essere processata in posti di comando, stazioni di polizia e caserme delle forze di sicurezza dello stato che sono abilitate a questo scopo."])}
      },
      "responsabilidad": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. RESPONSABILITÀ"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agenzia di viaggi sarà esonerata dalla sua responsabilità per la non esecuzione o la cattiva esecuzione del contratto nei seguenti casi: quando è dovuto a causa di forza maggiore (circostanza aliena, anormale e imprevedibile), quando è un evento che né l'agenzia organizzatrice né i fornitori avrebbero potuto evitare anche avendo esercitato la dovuta diligenza, o quando la causa è imputabile al cliente o a un terzo. Se a causa di condizioni meteorologiche, scioperi o altri motivi di forza maggiore i servizi sono interessati, l'organizzazione si impegna a svolgere tutte le azioni necessarie a favore dei passeggeri colpiti per queste situazioni, ma in ogni caso, l'incidenza economica, le spese di alloggio, manutenzione, trasporto e qualsiasi altra derivante come conseguenza di ciò, saranno esclusivamente a carico del cliente, lasciando l'organizzazione esente da queste responsabilità perché sono al di fuori del suo ambito di azione. Il cliente è obbligato a comunicare all'agenzia organizzatrice, il più presto possibile e per iscritto, qualsiasi inadempienza nell'esecuzione del contratto che abbia verificato sul posto."])}
      },
      "seguros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. ASSICURAZIONI"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel & Night opera con le assicurazioni richieste dal Real Decreto 271/1988, del 25 marzo, che regola le attività delle Agenzie di Viaggio."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le assicurazioni nominative saranno a carico dei clienti, se così disposto, come assicurazioni di annullamento o di copertura durante lo svolgimento dei viaggi."])}
      },
      "normas": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. REGOLE IMPERATIVE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei viaggi in autobus ogni cliente potrà portare solo una val,attrezzatura da sci. Non è permesso trasportare scatole o animali. Durante il viaggio in autobus, il cliente è obbligato a rispettare la convivenza pacifica con gli altri viaggiatori e a rispettare le norme della compagnia di trasporto (non fumare, non mangiare, non provocare disordini, ecc.). Il cliente dovrà pagare sul posto le cauzioni degli appartamenti. Né le tasse specifiche per ogni alloggio né le cauzioni sono incluse nel prezzo del viaggio. Le cauzioni verranno restituite alla fine dell'esperienza dall'alloggio, una volta verificato lo stato corretto dell'appartamento dall'agenzia immobiliare responsabile. L'organizzatore non si assume, in nessun caso, la responsabilità di eventuali controversie che possano derivare dal soggiorno dei clienti, anche se cercherà sempre di mediare per ottenere un risultato equo derivante dalle conseguenze dello stesso."])}
      },
      "datosPersonales": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. PROTEZIONE DEI DATI PERSONALI"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agenzia di viaggi sarà esonerata dalla sua responsabilità per la non esecuzione o la cattiva esecuzione del contratto nei seguenti casi: quando è dovuto a causa di forza maggiore (circostanza aliena, anormale e imprevedibile), quando è un evento che né l'agenzia organizzatrice né i fornitori avrebbero potuto evitare anche avendo esercitato la dovuta diligenza, o quando la causa è imputabile al cliente o a un terzo. Se a causa di condizioni meteorologiche, scioperi o altri motivi di forza maggiore i servizi sono interessati, l'organizzazione si impegna a svolgere tutte le azioni necessarie a favore dei passeggeri colpiti per queste situazioni, ma in ogni caso, l'incidenza economica, le spese di alloggio, manutenzione, trasporto e qualsiasi altra derivante come conseguenza di ciò, saranno esclusivamente a carico del cliente, lasciando l'organizzazione esente da queste responsabilità perché sono al di fuori del suo ambito di azione. Il cliente è obbligato a comunicare all'agenzia organizzatrice, il più presto possibile e per iscritto, qualsiasi inadempienza nell'esecuzione del contratto che abbia verificato sul posto."])},
        "apartados": {
          "responsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESPONSABILE"])},
          "responsableDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel&Night S.L."])},
          "finalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCOPO"])},
          "finalidadDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco contatti e gestione finanziaria, amministrativa e commerciale delle attività svolte da Travel&Night S.L."])},
          "legitimacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGITTIMAZIONE"])},
          "legitimacionDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celebrazione ed esecuzione di un contratto e consenso dell'interessato."])},
          "destinatario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESTINATARIO"])},
          "destinatarioDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi dati saranno condivisi con le aziende incaricate dell'alloggio prenotato, con le centrali di prenotazione degli hotel e degli appartamenti, con i fornitori di servizi esterni e con le entità assicurative e in tutti i casi in cui ciò sia stabilito per obbligo legale."])},
          "derechos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRITTI"])},
          "derechosDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di accesso, di rettifica, di cancellazione, di limitazione del trattamento, di portabilità e di opposizione dei dati come spiegato nelle informazioni aggiuntive."])},
          "adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMAZIONI AGGIUNTIVE"])},
          "adicionalDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile consultare le informazioni aggiuntive e dettagliate sulla nostra Politica di Protezione dei Dati inviando una richiesta a "])}
        }
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Spagna"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note Legali"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sui Cookie"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Generali"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguici!"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIGUARDO A NOI"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo una piattaforma digitale che sta rivoluzionando il settore dello sci, progettando esperienze sulla neve, sia individuali che di gruppo. Fin dalla nostra origine, abbiamo portato più di 30.000 viaggiatori in tutte le stazioni della Spagna, Andorra e Francia."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nostra specialità è collegare i viaggiatori e creare comunità attraverso il divertimento e lo sport sulla neve. Abbiamo i migliori après-ski e un festival proprio sulla neve, il Winter Snow Festival in Andorra. Inoltre, offriamo viaggi su misura per famiglie, aziende, scuole e gourmet, adattandoci alle esigenze e ai gusti dei nostri viaggiatori, offrendo loro la migliore offerta di relax, ristorazione e divertimento. "])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM DI SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALORI DI SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente giovane e vicino"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ski&Night siamo un'azienda giovane, formata da un team vicino e flessibile, il che ci permette di conoscere molto bene il prodotto che vendiamo."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il buon umore si trasmette quando si creano esperienze sulla neve e nella capacità di adattarci alle esigenze dei nostri viaggiatori, sia per universitari che per scuole, aziende, gourmet e famiglie. "])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onestà e trasparenza"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'onestà è una delle chiavi della nostra azienda, ed è la nostra massima quando si tratta di agire sia con i nostri viaggiatori che con i nostri fornitori."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin dalla nostra origine, più del 25.000 viaggiatori hanno fiducia in noi e un alto tasso di fedeltà che raggiunge l'83,8%."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impegno e senso di appartenenza"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il marchio Ski&Night è diventato uno stile di leadership e impegno, una comunità che è cresciuta dalla sua origine per diventare un marchio leader nel settore della neve."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un impegno che si estende non solo nella relazione con i viaggiatori, ma anche con i fornitori, le stazioni e l'ambiente naturale in cui svolgiamo la nostra attività. "])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANDI CIFRE GLOBALI"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNI DI ESPERIENZA"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAGGIATORI"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAGGI DI GRUPPO"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLLABORAZIONI"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIEPILOGO STAGIONE 2021/22"])}
      }
    }
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMODITÀ DI PAGAMENTO"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decidi se preferisci pagare l'intero viaggio o solo la tua parte e consulta lo stato nel tuo profilo."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSISTENZA PERSONALIZZATA"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nostro personale di assistenza clienti e le guide in loco risolveranno tutti i tuoi dubbi."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLESSIBILITÀ NEI TUOI VIAGGI"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli extra e i servizi di cui hai bisogno e personalizza al massimo il tuo viaggio."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVERTIMENTO SU MISURA"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goditi un'ampia offerta di relax, attività sulla neve, gastronomia e divertimento notturno."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viaggi di gruppo"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo dell'esperienza"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi"])},
    "tituloFiltroFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli le località in cui vorresti andare"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli l'intervallo di prezzi (€) in cui vuoi cercare"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli i festival a cui vorresti partecipare"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a uno di questi viaggi di gruppo e goditi l'esperienza con i tuoi amici. Puoi prenotare una nuova camera o unirti a una esistente."])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota uno dei nostri viaggi esclusivi sulla neve con esperienze relax, gourmet, culturali o estreme. Puoi anche contattarci direttamente a questo numero di telefono <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> e progetteremo un viaggio completamente adattato alle tue esigenze."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio disponibile"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggi disponibili"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la tua esperienza di gruppo, abbiamo questi alloggi disponibili"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi posizioni"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggi"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggio"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina dal prezzo più basso al più alto"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina dal prezzo più alto al più basso"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori offerte"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Ski and Night?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Sea and Night?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiutaci a trovare il miglior prezzo, organizza le tue camere"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il numero di camere/appartamenti"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulti"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambini"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambino"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età dei bambini"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i pubblici"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oltre 25 anni"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 18 a 25 anni"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 0 a 17 anni"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posti liberi nella stanza, nel caso in cui rimangano liberi verrà addebitato un supplemento di"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per ogni posto libero da ripartire tra i membri della stanza come 'Resto dell'alloggio'"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posto libero"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi invitare via email cliccando sul pulsante."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila le informazioni su Raccontaci qualcosa di te."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le politiche sulla privacy e la politica di alloggio a piena occupazione devono essere confermate."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le politiche di cancellazione devono essere confermate."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le domande del modulo devono essere risposte."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie prenotazioni"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca sul pulsante 'Riattiva' per recuperarla"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue prossime prenotazioni"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei QR"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei prossimi biglietti"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del pagamento della prenotazione"])},
    "mensajeCompra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando effettui un acquisto, apparirà qui"])},
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di pagamento"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale prenotazione"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa prenotazione si trova"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio viaggio"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pagato"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio eccezionale"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per la prenotazione"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora prenotazioni"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai prenotazioni di feste"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci di più su di te"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli come vuoi pagare"])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementi"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posti liberi"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di soggiorno"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione pagamento carta di credito"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di gestione di cambio"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa turistica"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione pagamento carta di credito"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di gestione del cambio"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acconto"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento totale dell'esperienza"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento parziale"])}
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensione completa"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezza pensione"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera e colazione"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo camera"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo colazione"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Gruppo"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul trasporto"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul tuo viaggio"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste prenotate"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea super-gruppo"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a un super-gruppo"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo gruppo non ha ancora creato un super-gruppo. Crea un super-gruppo o unisciti a uno esistente prima della data di prenotazione per viaggiare nello stesso autobus e alloggiare nelle camere più vicine possibili."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I super-gruppi saranno considerati solo per edifici vicini a causa del punto di arrivo."])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai parte del super-gruppo"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I super-gruppi saranno considerati solo per edifici vicini a causa del punto di arrivo."])}
    },
    "plazasLibres": {
      "modal": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono ancora posti liberi nella tua prenotazione!"])},
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["C'è ancora ", _interpolate(_named("plazasLibres")), " posto libero nella tua prenotazione!"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ci sono ancora ", _interpolate(_named("plazasLibres")), " posti liberi nella tua prenotazione!"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["C'è spazio per ", _interpolate(_list(0)), " persona nella tua prenotazione. Sarà aggiunto un supplemento di ", _interpolate(_list(1)), "€ a ogni persona confermata. Se i posti liberi verranno infine occupati, il supplemento sarà rimborsato."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["C'è spazio per altre ", _interpolate(_list(0)), " persone nella tua prenotazione. Sarà aggiunto un supplemento di ", _interpolate(_list(1)), "€ a ogni persona confermata. Se i posti liberi verranno infine occupati, il supplemento sarà rimborsato."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi supplemento alla prenotazione"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mancano persone alla prenotazione"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riempire i posti"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggruppa la tua prenotazione con altre. I gruppi saranno presi in considerazione per i servizi dell'esperienza, ad esempio nella distribuzione dei trasporti. Per creare un gruppo, invia il link agli utenti delle altre prenotazioni."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri del gruppo"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica voucher"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fine"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposte incluse"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluso nel pacchetto base"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio extra"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localizzatore"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email di contatto"])}
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulteriori informazioni sull'alloggio"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data limite per effettuare il pagamento o apportare modifiche ai servizi gratuitamente è prima del giorno"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento del tuo viaggio è stato già effettuato. Resta in attesa di ulteriori informazioni sulle feste."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora effettuato i pagamenti del tuo viaggio."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione/i da"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni la prenotazione"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possiamo aiutarti a trovare una soluzione alternativa se è necessario apportare modifiche alla tua prenotazione."])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossime esperienze:"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le condizioni di prenotazione per il viaggio sono le seguenti"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera la prenotazione"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il codice del tuo viaggio"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avete ", _interpolate(_named("plazaslibres")), " posto libero, condividete il link per completarli o verrà aggiunto un supplemento di ", _interpolate(_named("suplemento")), "€ a ogni persona confermata."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avete ", _interpolate(_named("plazaslibres")), " posti liberi, condividete il link per completarli o verrà aggiunto un supplemento di ", _interpolate(_named("suplemento")), "€ a ogni persona confermata."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vostra prenotazione è composta dalle seguenti persone:"])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se non viene completata entro ", _interpolate(_named("fechaLimite")), ", verrà automaticamente annullata."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Termine di pagamento: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il numero di posti non viene riempito, verrà aggiunto un supplemento per ogni persona confermata."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vediamo l'ora di vederti e di vivere questa esperienza insieme."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi aggiungere alcuni extra se li hai dimenticati"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi extra"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri extra"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi Contrattati"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il pagamento"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio di camera"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo totale"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se la cancellazione avviene tra il"])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I costi associati alla cancellazione della prenotazione sono:"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione è stata annullata"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attualmente hai già una prenotazione per questo viaggio, scegli un altro viaggio o consulta le tue prenotazioni."])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di sconto"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inseriscilo qui"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il codice sconto '", _interpolate(_named("codigo")), "'. Non è valido."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto Base"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai una prenotazione in sospeso per questo viaggio. Vuoi annullarla o continuarla?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla i dati della tua prenotazione"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga l'importo totale della prenotazione"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al termine della prenotazione inviare il link che forniremo al resto delle persone affinché possano iscriversi"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda che puoi pagare l'intera prenotazione o solo una parte"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga adesso"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e paga l'importo rimanente prima"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga a rate."])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione annullata"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accelerare il processo di check-in al tuo arrivo, è necessario compilare le tue informazioni. Puoi compilare i tuoi dati o quelli dei tuoi compagni di stanza/appartamento."])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi consultare i dati di prenotazione delle persone per le quali hai effettuato il primo pagamento"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai un codice di prenotazione"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcuno ha detto festa"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non sono disponibili feste per nessuno dei tuoi viaggi. Sarai informato via email non appena saranno confermate, così potrai prenotare il tuo posto."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai un amico che ha già prenotato e vuoi unirti alla sua camera, clicca sul pulsante sottostante"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per tassa turistica"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese extra di gestione"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di gestione della piattaforma"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa per il resto dell'alloggio"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per pagamento ritardato"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplemento per posti liberi"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posto"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libero"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio Rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasciaci una recensione"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguici"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestore di Rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta cliccato qui, seleziona i membri dalla tabella a destra"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza la Distribuzione"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la stanza che desideri distribuire e poi le persone che desideri mettere in quella stanza dalla tabella a sinistra"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Aggiungi membri"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona i membri dalla tabella"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In modifica"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicato"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non distribuiti"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuiti"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca nome"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])}
    },
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai finito?"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non hai finito di distribuire e vuoi continuare più tardi, clicca su 'Salva e esci'. Se hai finito, clicca su 'Invia', nota che una volta inviato, non potranno essere apportate modifiche."])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva e esci"])},
    "suplementoTitulo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ci sono posti liberi nella tua distribuzione, verrà aggiunto un supplemento di ", _interpolate(_named("XX")), "€ a ogni viaggiatore. Sei sicuro di voler continuare?"])},
    "suplementoMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile apportare modifiche una volta confermata la distribuzione."])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, continua"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, torna indietro"])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella pubblicazione del tuo rooming."])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel salvare il tuo viaggio."])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migliori offerte"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostello"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monolocale"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicabile"])}
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line-up confermato"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente..."])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza immagini"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividere"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda il viaggio che ho trovato!"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutte le esperienze"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni il tuo biglietto"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRA DI PIÙ >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRA DI MENO >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni il tuo abbonamento"])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutte le foto"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi mappa"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'alloggio"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari e condizioni speciali"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanze"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso alle piste"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di interesse"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'alloggio"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regimi disponibili e tipi di camera"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dalla stazione sciistica"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento GRATUITO"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente rimborsabile"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre opzioni di viaggio"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi dell'hotel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'alloggio"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra alloggi"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagatore: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERRORE: BIGLIETTO GIA' VALIDATO"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIGLIETTO VALIDATO CON SUCCESSO"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validato il "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" alle "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unità"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi convalidare il biglietto?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalidare"])}
  },
  "general": {
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prima del"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ora"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Online"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articoli correlati"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ora sei aggiornato con il tuo programma di pagamento, puoi effettuare il pagamento successivo a partire dal ", _interpolate(_named("fecha")), "."])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi di più"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere meno"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio profilo"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in effettuato"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice è stato copiato"])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento in sospeso"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anni"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzione"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggio"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra di più"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra meno"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze su misura"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partire da"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini e condizioni"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione dei dati personali"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni su questo viaggio"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni sull'alloggio"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varie occupazioni disponibili"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo alloggio"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio saperne di più"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutti"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota alloggio"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a stanza"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi mappa"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere le posizioni"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice non è valido."])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenza"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggio per"])},
    "diaMayus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIORNO"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notti"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notte"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassunto"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posti"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Più di ", _interpolate(_named("cantidad")), " disponibili"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibili"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esaurite"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista d'attesa"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è stata aggiunta con successo alla lista d'attesa"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile aggiungere alla lista d'attesa"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bambino"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persona"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulti"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bambini"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persone"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambino"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riservazione è per me"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzeremo il nome e il cognome dal tuo profilo"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono minorenne"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più informazioni"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ informazioni"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È minorenne"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice non valido"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare i filtri"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra l'alloggio"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli le località in cui desideri andare"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tipo di alloggio in cui desideri soggiornare"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il numero di posti"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la gamma"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il servizio"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione di Annullamento"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione di Viaggio"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni di alloggio"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni di skipass"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione dell'alloggio"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma prenotazione"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione del festival"])}
  },
  "botones": {
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota alloggio"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota il pacchetto festa"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alla stanza"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna ai viaggi"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornare alle mie prenotazioni"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dettagli"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza prenotazione"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il buono allegato"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullata"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entra"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica e paga"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi persona"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disiscriviti"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta selezione"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla prenotazione"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica prenotazione"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reattiva prenotazione"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga il deposito"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi Bono"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuare"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete un codice sconto?"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITICA SUI COOKIE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizziamo i cookie per migliorare i nostri servizi, analizzare e personalizzare la tua navigazione. Per continuare a navigare sul nostro sito web, devi accettare il loro utilizzo. Puoi cambiare le impostazioni o ottenere ulteriori informazioni sulla nostra"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto tutti i cookie"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica dei cookie"])},
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci i cookie"])},
    "extras": {
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessari"])},
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analitici"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
    },
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COSA SONO I COOKIE?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORIZZAZIONE ALL'USO DEI COOKIE?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DI COOKIE UTILIZZATI?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COME DISATTIVARE O ELIMINARE I COOKIE?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie sono file di testo memorizzati sul dispositivo dell'utente durante la navigazione su Internet. Contengono un numero che consente di identificare univocamente il dispositivo, anche se questo cambia posizione o indirizzo IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie vengono installati durante la navigazione su Internet, sia dai siti web visitati dall'utente sia da terze parti con cui il sito web interagisce. Questi permettono al sito web di conoscere l'attività dell'utente sullo stesso sito o su altri con cui è in relazione. Ad esempio: il luogo di accesso, il tempo di connessione, il dispositivo utilizzato (fisso o mobile), il sistema operativo e il browser utilizzati, le pagine più visitate, il numero di clic effettuati e dati sul comportamento dell'utente su Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie sono associati solo al browser e non forniscono di per sé dati personali. I cookie non possono danneggiare il dispositivo e sono molto utili per identificare e risolvere errori. Il sito web è accessibile anche senza l'attivazione dei cookie, tuttavia, la loro disattivazione potrebbe impedire il corretto funzionamento dello stesso."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In conformità all'avviso sui cookie visualizzato nel piè di pagina del sito web, l'utente o il visitatore del sito accetta esplicitamente l'uso dei cookie come descritto di seguito, a meno che non abbia modificato le impostazioni del proprio browser per rifiutarne l'uso."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Titolare del sito web utilizza cookie strettamente necessari per facilitare la corretta navigazione nel sito Web e per assicurare che i contenuti vengano caricati in modo efficace. Questi cookie sono anche cookie di sessione, cioè temporanei, e scadono ed eliminano automaticamente quando l'utente chiude il browser."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie di Facebook Ireland Ltd, utilizzati per consentire ai visitatori di interagire con i contenuti di Facebook e generati solo per gli utenti di tali social media. Le condizioni d'uso di questi cookie e le informazioni raccolte sono regolate dalla politica sulla privacy della piattaforma di social media corrispondente."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie possono essere disattivati ed eliminati seguendo le seguenti guide in base al browser utilizzato:"])},
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare e gestire i cookie in Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare, abilitare e gestire i cookie in Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare i cookie in Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni web di Safari su iPhone, iPad e iPod touch"])}
      }
    }
  },
  "politicasCancelacion": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiche di Cancellazione"])}
  },
  "titulos": {
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerario"])},
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluso"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota per quante persone vuoi"])},
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa i seguenti dati per continuare con la tua prenotazione"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivi di Cancellazione"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Profilo"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al termine della prenotazione, invia il link che forniremo al resto delle persone affinché possano unirsi."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome della tua camera"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come ci hai conosciuti?"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai studiando?"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un'università"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la tua risposta"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del commerciale"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In quale azienda lavori?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo Totale"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo in Sospeso"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Prenotazione"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di Prenotazione"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Viaggio"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inseriscilo qui"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Miei Viaggiatori"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Mio Profilo"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicità via Email"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica il Documento d'Identità"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Password"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai esaurito l'alloggio?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila i seguenti dati e, se una camera dovesse diventare disponibile, sarai avvisato"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri unirti a una camera di un viaggio, utilizza il codice fornito dalla persona che ha effettuato la prenotazione"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e Cognome"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo Email"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di Posti"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osservazioni"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerciale"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto Base"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome della tua camera"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il campo"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati dell'Acquirente"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei ancora cliente? Registrati"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la tua password"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera Password"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei già un cliente? Accedi"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei già un utente"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la Password"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la Password dell'Utente"])}
  },
  "viaje": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il codice del tuo viaggio"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai tra 5 e 17 anni e vuoi approfittare al massimo dei giorni di sci, questo è il tuo viaggio. Migliora la tua tecnica e goditi la natura, noi ti forniamo il trasporto, l'alloggio e gli istruttori."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti offriamo l'alloggio più comodo per viaggiare sulla neve con la tua famiglia. Inoltre, puoi prenotare lezioni di sci per i bambini e molte altre attività adatte alle tue esigenze."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggiare con i tuoi colleghi è molto facile. Se hai più di 25 anni, unisciti ai nostri viaggi organizzati per incontrare persone come te, in cui lo sci e l'intrattenimento notturno ti sorprenderanno."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il tuo viaggio se desideri incontrare persone della tua età nei migliori festival e après-ski. Ti senti pronto per vivere un'esperienza indimenticabile sulla neve?"])}
  },
  "errors": {
    "habitacion": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice non corrisponde a nessuna stanza"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono prenotazioni da confermare"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'esperienza non ammette nuove prenotazioni"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno dei viaggiatori ha già prenotato per questa esperienza"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il pagamento"])},
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento per questa prenotazione avviene tramite bonifico bancario"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema nel caricamento delle informazioni. Riprova più tardi"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il viaggio richiesto non esiste"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il viaggio non accetta una nuova prenotazione"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore imprevisto. Riprova più tardi"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato rilevato un cambiamento di prezzo"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per eliminare la prenotazione"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per eliminare la prenotazione"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare la prenotazione"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore imprevisto. Riprova più tardi"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono campi obbligatori non compilati"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per creare la prenotazione"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per creare la prenotazione"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile trovare le informazioni sulla prenotazione"])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già una prenotazione per questo viaggio"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indirizzo email è già registrato"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per eseguire il processo"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono campi non compilati"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La risorsa richiesta non è stata trovata"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste un conflitto nello stato della prenotazione"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'inserimento della password"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per continuare"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione non è in stato pendente"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun alloggio trovato"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, prova a prenotare più tardi. Se l'errore persiste, contattaci."])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni di gestione"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commissioni di ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skipass"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi cambiare i giorni del tuo skipass. Seleziona i giorni desiderati"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri il pranzo sulle piste"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporti"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo mezzo di trasporto"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazioni"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la tua assicurazione"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salute"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli l'attività"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri il pranzo sulle piste"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il biglietto che preferisci e vieni a goderti l'esperienza completa"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezioni"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi scegliere i giorni delle lezioni. Seleziona i giorni desiderati"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noleggi"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri noleggiare l'attrezzatura"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri un Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri un servizio relax."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personale"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il personale."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tuo parcheggio preferito"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animali Domestici"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli i servizi per gli animali domestici"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concorso"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la competizione"])}
    }
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai ancora ", _interpolate(_named("plazasLibres")), " posti disponibili nel tuo alloggio, che corrispondono a un pagamento in sospeso di ", _interpolate(_named("pagoPendiente")), " €. Hai due opzioni:"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi il link per completare i posti disponibili."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pagare l'importo pendente di ", _interpolate(_named("pagoPendiente")), " € tra le persone confermate."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se nessuna delle due opzioni viene effettuata prima del ", _interpolate(_named("fecha")), ", la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prenotazione verrà automaticamente cancellata."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga l'importo in sospeso"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia link"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagnolo"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])}
  }
}