<template>
	<div id="modalFotos" class="modal fade" tabindex="-1" aria-labelledby="modalFotosLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="modalFotosLabel" class="modal-title">{{ nombreAlojamiento }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body">
					<div
						ref="slider"
						class="d-flex overflow-auto gap-3 mb-3 slider-categorias"
						@mousedown="startDragging"
						@mouseup="stopDragging"
						@mouseleave="stopDragging"
						@mousemove="onDrag">
						<div
							v-for="(imagenes, categoria) in categoriasFotos"
							:key="categoria"
							class="categoria-item"
							:class="{ 'categoria-seleccionada': categoriaSeleccionada === categoria }"
							@click.prevent="handleClick(categoria)">
							<div class="categoria-imagen" :style="{ backgroundImage: 'url(' + imagenes[0].url + ')' }"></div>
							<span class="d-block text-truncate categoria-nombre">{{ getCategoriaNombre(categoria) }}</span>
						</div>
					</div>
					<LightBoxPhotos
						v-if="categoriasFotos[categoriaSeleccionada] && categoriasFotos[categoriaSeleccionada].length"
						:key="categoriaSeleccionada"
						:imagenes="categoriasFotos[categoriaSeleccionada].map(img => img.url)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, watch } from 'vue';
	import LightBoxPhotos from '@/components/utils/lightBoxPhotos.vue';
	import { useI18n } from 'vue-i18n';

	const props = defineProps({
		informacionAlojamiento: {
			type: Object,
			default: () => ({}),
		},
		nombreAlojamiento: {
			type: String,
			default: '',
		},
	});

	const { t } = useI18n();
	const isDragging = ref(false);
	const startX = ref(0);
	const scrollLeft = ref(0);
	const slider = ref(null);
	const dragThreshold = 5;
	const isClick = ref(true);
	const categoriaSeleccionada = ref('galeria');

	const categoriasFotos = computed(() => {
		const categorias = {};

		categorias['galeria'] = [];

		if (props.informacionAlojamiento?.imagenPrincipal) {
			categorias['galeria'].push(props.informacionAlojamiento.imagenPrincipal);
		}

		if (props.informacionAlojamiento?.imagenesSecundarias) {
			props.informacionAlojamiento.imagenesSecundarias.forEach(imagen => {
				categorias['galeria'].push(imagen);
			});
		}

		if (props.informacionAlojamiento?.imagenPrincipal && props.informacionAlojamiento.imagenPrincipal.tipos) {
			props.informacionAlojamiento.imagenPrincipal.tipos.forEach(tipo => {
				const claveTipo = tipo.nombre.toLowerCase();
				if (!categorias[claveTipo]) categorias[claveTipo] = [];
				categorias[claveTipo].push(props.informacionAlojamiento.imagenPrincipal);
			});
		}

		if (props.informacionAlojamiento?.imagenesSecundarias) {
			props.informacionAlojamiento.imagenesSecundarias.forEach(imagen => {
				imagen.tipos.forEach(tipo => {
					const claveTipo = tipo.nombre.toLowerCase();
					if (!categorias[claveTipo]) categorias[claveTipo] = [];
					categorias[claveTipo].push(imagen);
				});
			});
		}

		return categorias;
	});

	function startDragging(e) {
		isDragging.value = true;
		isClick.value = true;
		startX.value = e.pageX - slider.value.offsetLeft;
		scrollLeft.value = slider.value.scrollLeft;
	}

	function stopDragging() {
		isDragging.value = false;
	}

	function onDrag(e) {
		if (!isDragging.value) return;
		e.preventDefault();
		const x = e.pageX - slider.value.offsetLeft;
		const walk = (x - startX.value) * 1;
		if (Math.abs(walk) > dragThreshold) {
			isClick.value = false;
		}
		slider.value.scrollLeft = scrollLeft.value - walk;
	}

	function handleClick(categoria) {
		if (isClick.value) {
			categoriaSeleccionada.value = categoria;
		}
		isClick.value = true;
	}

	function getCategoriaNombre(categoria) {
		const traduccion = t(`alojamiento.tipos.${categoria}`);
		return traduccion === `alojamiento.tipos.${categoria}` ? categoria : traduccion;
	}

	watch(categoriasFotos, function () {
		if (!categoriaSeleccionada.value && Object.keys(categoriasFotos.value).length > 0) {
			categoriaSeleccionada.value = 'galeria';
		}
	});
</script>

<style lang="scss" scoped>
	.modal-title {
		color: var(--bs-primary);
		max-width: 85%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.modal-body::-webkit-scrollbar {
		width: 8px;
	}

	.modal-body::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 10px;
	}

	.categoria-item {
		cursor: pointer;
		color: var(--bs-primary);
		width: 120px;
	}

	.categoria-imagen {
		width: 120px;
		height: 90px;
		background-size: cover;
		background-position: center;
		border-radius: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.slider-categorias {
		overflow-x: auto;
		white-space: nowrap;
		gap: 15px;
		padding-bottom: 10px;
	}

	.slider-categorias::-webkit-scrollbar {
		height: 8px;
	}

	.slider-categorias::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 10px;
	}

	.categoria-seleccionada .categoria-imagen {
		border: 2px solid var(--bs-secondary);
	}
</style>
