<template>
	<template v-if="festival">
		<div class="pb-4">
			<FestivalHeader :festival="festival"></FestivalHeader>
			<div class="px-3 my-5 container justify-center">
				<div class="title-desktop text-primary fw-bold text-center mb-2">{{ festival.titulo }}</div>
				<p id="descripcion" v-sanitize-html="festival.descripcion" class="descripcion-festival text-center" />
			</div>
			<div v-if="festival.viajes && festival.viajes.length > 0" class="px-3 mt-5 container">
				<div class="d-flex justify-content-between align-items-center">
					<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.nuestrasExperiencias') }}</p>
				</div>
				<CarouselViajesCatalogo :lista-viajes="festival.viajes" class="viaje-area__viajes" />
			</div>

			<div v-if="festival.video" class="container px-3 my-3">
				<div class="row gx-0">
					<div class="col-12">
						<div class="me-0 mb-5">
							<iframe width="100%" :height="movil ? 250 : 500" :src="festival.video" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>

			<div v-if="festival.imagenesCarrusel.length > 0" class="container px-3">
				<div class="d-flex justify-content-between align-items-center">
					<p class="fs-3 titulo-lineup m-0 mb-1">{{ $t('alojamiento.imagenes') }}</p>
				</div>
				<CarouselFestivales :lista-fotos="festival.imagenesCarrusel" />
			</div>
			<template v-if="viajesAux && viajesAux.length > 0">
				<div class="px-3 my-4">
					<div class="d-flex justify-content-between align-items-center">
						<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.mejoresOfertas') }}</p>
						<a
							class="text-end align-self-center cursor-pointer text-decoration-underline fs-6 fs-lg-2"
							@click="goBuscadorFestival"
							>{{ $t('alojamiento.todasExperiencias') }}</a
						>
					</div>
					<div v-if="!isMobile" class="d-md-block">
						<div class="row gy-5 mt-1">
							<div v-for="viaje in viajesAux" :key="viaje.id" class="col-6 col-lg-4 col-xl-3">
								<trip-card
									:tipos-viaje="buscador.filtrosViajes.tiposGrupo"
									:viaje-tarjeta="viaje"
									@unirse-habitacion="showUnirseHabitacionModal = true" />
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-if="festival.artistas && festival.artistas.length > 0">
				<base-carousel
					:items-carousel="festival.artistas"
					:is-loading="false"
					:autoplay-time="5000"
					:max-elements="4"
					class="mx-auto px-2"
					style="max-width: 1440px">
					<template #title>
						<div class="d-flex justify-content-between align-items-center">
							<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.lineUp') }}</p>
						</div>
					</template>
					<template #itemCarousel="itemCarouselProps">
						<div class="d-flex flex-column gap-3">
							<img class="img-carousel-lineup" :src="itemCarouselProps.data.imagenArtista" alt="" />
							<iframe
								style="border-radius: var(--bs-border-radius-xl); padding: 0 20px"
								:src="itemCarouselProps.data.urlCancion"
								width="100%"
								height="102"
								frameBorder="0"
								allowfullscreen=""
								allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
								loading="lazy" />
						</div>
					</template>
				</base-carousel>
			</template>
		</div>
	</template>

	<template v-else>
		<Spinner />
	</template>
</template>
<script setup>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import { festivalesStore } from '@/store_pinia/festivales';
	import { useRoute, useRouter } from 'vue-router';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import CarouselViajesCatalogo from '@/components/Carousel/CarouselViajesCatalogo.vue';
	import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
	import {
		filtrarViajesPorFestival,
		obtenerIdAreaPorNombre,
		obtenerIdEstacionPorNombre,
		obtenerIdGrupoPorNombre,
	} from '@/helpers/viajesGrupos';
	import { viajesGrupos } from '@/store_pinia/viajesGrupo';
	import { buscadorGrupos } from '@/store_pinia/buscadorGrupos';
	import { filtroGruposStore } from '@/store_pinia/filtros/filtroGrupos';
	import { appGlobalStore } from '@/store_pinia/app';
	import TripCard from '@/components/card/TripCard/TripCard.vue';
	import Spinner from '@/components/SpinnerLoading.vue';
	import CarouselFestivales from '@/components/Carousel/CarouselFestivales.vue';
	import FestivalHeader from './FestivalHeader.vue';
	import EventosApiService from 'src/services/api/skiandnight/EventosApiService';

	const festival = ref();
	const store_pinia_festivales = festivalesStore();
	const route = useRoute();
	const router = useRouter();
	const isMobile = ref(window.innerWidth <= 1033);

	const viajesAux = ref([]);
	const viajesGruposStore = viajesGrupos();
	const buscador = buscadorGrupos();
	const viajesGrupoFiltroStore = filtroGruposStore();
	const storeGlobal = appGlobalStore();

	const movil = computed(() => window.screen.availWidth <= 575);

	function getListaViajesGrupoCatalogo(area = null, destino, nombreGrupo) {
		const listaFiltroDestino = buscador.filtrosViajes.destino;
		const listaFiltroGrupos = buscador.filtrosViajes.tiposGrupo;

		const idArea = obtenerIdAreaPorNombre(area, listaFiltroDestino);
		const idEstacion = obtenerIdEstacionPorNombre(destino, listaFiltroDestino);
		const idGrupo = obtenerIdGrupoPorNombre(nombreGrupo, listaFiltroGrupos);
		const fechaInicioViaje = route.query.fechaInicio;
		const fechaFinViaje = route.query.fechaFin;
		const nombreFestival = route.query.festival;

		ViajesGrupoApiService.getViajes(idArea, idEstacion, idGrupo, fechaInicioViaje, fechaFinViaje).then(
			listaViajesTotal => {
				viajesGruposStore.setViajesGruposTotal(listaViajesTotal).then(() => {
					viajesGrupoFiltroStore.setViajesFiltradosInicial(filtrarViajesPorFestival(nombreFestival, listaViajesTotal));
					viajesGrupoFiltroStore.setFiltroFestivales([{ nombreFestival: festival.value.titulo }]);
					viajesGrupoFiltroStore.aplicarFiltros();

					viajesAux.value = [].concat.apply([], Object.values(viajesGrupoFiltroStore.viajesFiltradosAplicado));
				});
			}
		);
	}

	function handleResize() {
		isMobile.value = window.innerWidth <= 1033;
	}

	function goBuscadorFestival() {
		router.push({
			name: 'GruposCatalogo',
			query: {
				festival: festival.value.titulo,
			},
		});
	}

	onMounted(async () => {
		storeGlobal.loadingGlobal = true;

		const metaData = await EventosApiService.getFestivalSeo(route.params.uri);
		if (metaData['titulo'] && metaData['descripcion']) {
			document.title = metaData.titulo;
			document.querySelector("[name='description']").remove();
			const meta = document.createElement('meta');
			meta.name = 'description';
			meta.content = metaData.descripcion;
			document.getElementsByTagName('head')[0].appendChild(meta);
		}

		await store_pinia_festivales.setFestival(route.params.uri);
		festival.value = store_pinia_festivales.festival;

		await buscador.setFilterInformation();

		getListaViajesGrupoCatalogo(null, route.query.lugarExperiencia, route.query.grupo);

		storeGlobal.loadingGlobal = false;

		window.addEventListener('resize', handleResize);
	});

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});
</script>

<style lang="scss" scoped>
	.btn-secondary {
		font-size: 0.9rem;
		border-radius: var(--bs-border-radius-xl);
	}

	.festivales-detalle {
		background-color: white;
		min-height: 40vmax;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}

	@media (max-width: 768px) {
		.festivales-detalle {
			min-height: 75vmax;
		}
	}

	.festival-img-fondo {
		min-height: 90vh;
		background-size: cover;
		background-position: top;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.imagenes-secundarias-festival {
		margin-top: -11vmax;
	}

	.titulo-descripcion {
		font-weight: 700;
		font-size: 1.2rem;
		opacity: 0.7;
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.descripcion-festival {
		font-size: 0.95rem;
	}

	.ubicacion-festival {
		font-size: 1.3rem;
		margin: 0px;
		font-weight: 700;
		opacity: 0.7;
	}

	.titulo {
		font-weight: 800;
	}

	.subtitulo {
		font-weight: 800;
	}

	.img-carousel {
		border-radius: var(--bs-border-radius-xl);
		width: 280px;
		height: 180px;
		background-size: cover;
		padding-right: 100px;
		background-position: center;
	}

	.titulo-lineup {
		font-weight: 700;
	}

	.fecha-festival {
		font-weight: 800;
	}

	.titulo-sponsors {
		font-weight: 700;
	}

	.img-carousel-lineup {
		width: 100%;
		padding: 20px;
	}

	.ver-mapa {
		font-size: 0.9rem;
	}

	:deep(.carousel__slide) {
		flex-shrink: 1;
		gap: 10px;
		margin-right: 10px;
		margin-left: 3px;
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	@media (max-width: 575px) {
		.img-carousel {
			width: 180px;
			height: 130px;
			margin-top: 60px;
		}
		.festival-img-fondo {
			min-height: 65vh;
		}
	}
</style>
