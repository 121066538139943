import { firstLetterCapital } from '@/helpers/languageUtils';
import { useI18n } from 'vue-i18n';


export function getServicioUniqueId(servicio) {
	if (servicio.subtipo) {
		return `${servicio.tipo}${firstLetterCapital(servicio.subtipo)}`;
	} else {
		return servicio.tipo;
	}
}

export function isServicioSelectable(reserva, servicio) {
	return !reserva.persisted || !servicio.contratableSoloCreacion;
}

export function isServicioChangeable(reserva, servicio) {
	return servicio == null || !reserva.persisted || servicio.borrable || servicio.justSelected;
}

export const getCategoriasServiciosMetaData = () => {
	const { t } = useI18n();
	return [
		{
			selector: 'forfait',
			titulo: t('servicios.forfait.titulo'),
			subtitulo: t('servicios.forfait.subtitulo'),
			icono: 'fa-solid fa-credit-card fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'restauracion',
			titulo: t('servicios.restauracion.titulo'),
			subtitulo: t('servicios.restauracion.subtitulo'),
			icono: 'fa-solid fa-bowl-food fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'alquiler',
			titulo: t('servicios.alquiler.titulo'),
			subtitulo: t('servicios.alquiler.subtitulo'),
			icono: 'fa-solid fa-person-skiing-nordic fa-2x',
			tipo: 'subfiltro',
		},
		{
			selector: 'clases',
			titulo: t('servicios.clase.titulo'),
			subtitulo: t('servicios.clase.subtitulo'),
			icono: 'fa-solid fa-person-skiing fa-2x',
			tipo: 'subfiltro',
		},
		{
			selector: 'transporte',
			titulo: t('servicios.transporte.titulo'),
			subtitulo: t('servicios.transporte.subtitulo'),
			icono: 'fa-solid fa-van-shuttle fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'actividad',
			titulo: t('servicios.actividad.titulo'),
			subtitulo: t('servicios.actividad.subtitulo'),
			icono: 'fa-solid fa-plus',
			tipo: 'subtipo',
		},
		{
			selector: 'seguro',
			titulo: t('servicios.seguro.titulo'),
			subtitulo: t('servicios.seguro.subtitulo'),
			icono: 'fa-solid fa-notes-medical fa-2x',
			tipo: 'subtipo',
		},
		{
			selector: 'parking',
			titulo: t('servicios.parking.titulo'),
			subtitulo: t('servicios.parking.subtitulo'),
			icono: 'fa-solid fa-car fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'greenFee',
			titulo: t('servicios.greenFee.titulo'),
			subtitulo: t('servicios.greenFee.subtitulo'),
			icono: 'fa-solid fa-golf-ball-tee fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'comfort',
			titulo: t('servicios.relax.titulo'),
			subtitulo: t('servicios.relax.subtitulo'),
			icono: 'fa-solid fa-face-smile-beam fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'festival',
			titulo: t('servicios.festival.titulo'),
			subtitulo: t('servicios.festival.subtitulo'),
			icono: 'fa-solid fa-music fa-2x',
			tipo: 'subtipo',
		},
		{
			selector: 'personal',
			titulo: t('servicios.personal.titulo'),
			subtitulo: t('servicios.personal.subtitulo'),
			icono: 'fa-solid fa-user-group fa-2x',
			tipo: 'subtipo',
		},
		{
			selector: 'mascota',
			titulo: t('servicios.mascota.titulo'),
			subtitulo: t('servicios.mascota.subtitulo'),
			icono: 'fa-solid fa-dog fa-2x',
			tipo: 'individual',
		},
		{
			selector: 'competicion',
			titulo: t('servicios.competicion.titulo'),
			subtitulo: t('servicios.competicion.subtitulo'),
			icono: 'fa-solid fa-trophy fa-2x',
			tipo: 'subfiltro',
		},
	];
};
