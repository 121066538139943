<template>
	<div>
		<div v-if="festivalesVisible.length > 0" class="festivales-container">
			<div class="festivales-grid">
				<div class="section-header">
					<h2>{{ $t('home.festivales.title') }}</h2>
					<p>{{ $t('home.festivales.subtitle') }}</p>
				</div>
				<div
					v-for="(festival, index) in festivalesVisible"
					:key="festival.id"
					:class="['festival-item', getClassByIndex(index)]"
					:style="getFestivalBackground(festival.imagenDesktop)"
					class="pointer"
					@click="goToFestival(festival.uri)">
					<div class="festival-content">
						<div class="festival-header">
							<div v-if="festival.icono" class="festival-icon">
								<img :src="festival.icono" alt="Icon" />
							</div>
							<div v-else class="festival-title festival-title-text">
								<div>{{ festival.titulo }}</div>
							</div>
						</div>
						<div class="festival-date">
							<CalendaryIcon class="me-2" color="white" />
							<span>{{ formatFestivalDates(festival.fechaInicio, festival.fechaFin) }}</span>
						</div>
					</div>
					<div class="festival-location mb-3">
						<LocationIcon class="me-2" width="20px" color="white" />
						<span>{{ festival.subtitulo }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted } from 'vue';
	import { useRouter } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import CalendaryIcon from '@/components/Icons/CalendaryIcon.vue';
	import LocationIcon from '@/components/Icons/LocationIcon.vue';
	import EventosApiService from '@/services/api/skiandnight/EventosApiService';

	const { locale } = useI18n();
	const router = useRouter();

	const festivales = ref([]);

	const festivalOrder = ['wintersnowfestival2025', 'afterworkinthesnow2025', 'andorra', 'formigal', 'alpesfranceses'];

	const festivalesVisible = computed(() => {
		const orderedFestivales = festivales.value.slice().sort((a, b) => {
			const indexA = festivalOrder.indexOf(a.uri);
			const indexB = festivalOrder.indexOf(b.uri);

			if (indexA !== -1 && indexB !== -1) {
				return indexA - indexB;
			} else if (indexA !== -1) {
				return -1;
			} else if (indexB !== -1) {
				return 1;
			}
			return 0;
		});

		return orderedFestivales.slice(0, 7);
	});

	async function fetchFestivales() {
		try {
			const response = await EventosApiService.getFestivales();
			festivales.value = response;
		} catch (error) {
			console.error(error);
		}
	}

	function formatFestivalDates(start, end) {
		const startDate = new Date(start);
		const endDate = new Date(end);
		const options = { day: '2-digit', month: 'short' };
		const selectedLanguage = locale.value;

		function formatDate(date) {
			return new Intl.DateTimeFormat(selectedLanguage, options).format(date);
		}

		const startFormatted = formatDate(startDate);
		const endFormatted = formatDate(endDate);

		if (startDate.getMonth() === endDate.getMonth()) {
			return `${startDate.getDate()} - ${endFormatted}`;
		}
		return `${startFormatted} - ${endFormatted}`;
	}

	function getFestivalBackground(image) {
		return {
			background: `linear-gradient(0deg, rgba(var(--bs-primary-rgb), 0.5) 0%, rgba(var(--bs-primary-rgb), 0.5) 100%), url(${image}) lightgray 50% / cover no-repeat`,
		};
	}

	function getClassByIndex(index) {
		const length = festivalesVisible.value.length;
		if (length === 1) return 'col-12';
		if (length === 3 || length === 6) return 'col-4';
		if (length === 7) {
			if (index < 2 || index >= 5) return 'col-6';
			return 'col-4';
		}
		if (length % 2 !== 0) {
			if (index < 2) return 'col-6';
			return 'col-4';
		}
		return 'col-6';
	}

	function goToFestival(uri) {
		router.push({
			name: 'FestivalesDetalle',
			params: { uri: uri },
		});
	}

	onMounted(() => {
		fetchFestivales();
	});
</script>

<style scoped lang="scss">
	.section-header {
		text-align: left;
		margin-bottom: 19px;
	}

	.section-header h2 {
		font-size: 28px;
		font-style: italic;
		font-weight: 700;
		text-transform: uppercase;
		color: #ffffff;
	}

	.section-header p {
		font-size: 1.2rem;
		color: #ffffff;
		line-height: 1.5;
		margin: 0 auto;
	}

	.festivales-container {
		width: 100%;
		justify-content: center;
		position: relative;
		z-index: 1;
		overflow: hidden;
	}

	.festivales-container::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--bs-primary);
		clip-path: polygon(
			100.146% 0%,
			0% 0%,
			0% 17.505%,
			0% 37.631%,
			0% 75.996%,
			100% 100%,
			100% 37.631%,
			100.146% 37.631%,
			100.146% 0%
		);
		z-index: -1;
	}

	.festivales-grid {
		display: flex;
		flex-wrap: wrap;
		padding: 50px 90px;
		z-index: 2;
	}

	.festival-item {
		height: 400px;
		position: relative;
		overflow: hidden;
		color: white;
		display: flex;
		flex-direction: column;
		transition: transform 0.3s ease-in-out;
		justify-content: space-between;
		cursor: pointer; /* Añadido para mostrar que es clickeable */
	}

	.festival-item:hover {
		transform: scale(1.05);
		z-index: 1001;
	}

	.festival-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		flex-grow: 1;
		text-align: center;
	}

	.festival-header {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}

	.festival-icon img {
		max-width: 40%;
		max-height: 300px;
	}

	.festival-title-text {
		color: #ffffff;
		text-align: center;
		text-shadow: 0px 0px 1px rgba(var(--bs-primary-rgb), 0.4), 2px 4px 5px rgba(var(--bs-primary-rgb), 0.1),
			4px 6px 20px rgba(var(--bs-primary-rgb), 0.1);
		font-size: 40px;
		font-weight: 700;
		text-transform: uppercase;
	}

	.festival-date {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		margin-top: 10px;
	}

	.festival-location {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		padding-bottom: 10px;
		width: 100%;
	}

	.festival-location span {
		margin-left: 4px;
	}

	@media (max-width: 1024px) {
		.festival-item {
			width: 100% !important;
		}

		.festivales-grid {
			display: flex;
			flex-wrap: wrap;
			padding: 30px;
			z-index: 2;
		}
	}
</style>
