<template>
	<carousel :items-to-show="6.5" wrap-around :autoplay="4000" :breakpoints="breakpoints">
		<slide v-for="slide in listaFotos" :key="slide">
			<div class="img-carousel" :style="`background-image: url(${slide})`" />
		</slide>
	</carousel>
</template>
<script setup>
	import { Carousel, Slide } from 'vue3-carousel';
	import { computed } from 'vue';

	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		listaFotos: {
			type: Array,
			required: true,
		},
	});

	const breakpoints = computed(() => ({
		0: {
			itemsToShow: 1,
			snapAlign: 'center',
		},
		500: {
			itemsToShow: 1.4,
			snapAlign: 'center',
		},
		600: {
			itemsToShow: 1.9,
			snapAlign: 'center',
		},
		800: {
			itemsToShow: 2.5,
			snapAlign: 'center',
		},
		1024: {
			itemsToShow: 3,
			snapAlign: 'start',
		},
		1300: {
			itemsToShow: 4,
			snapAlign: 'start',
		},
		1500: {
			itemsToShow: 5,
			snapAlign: 'start',
		},
		1800: {
			itemsToShow: 5.5,
			snapAlign: 'start',
		},
	}));
</script>

<style lang="css" scoped>
	.img-carousel {
		border-radius: var(--bs-border-radius-lg);
		width: 300px;
		height: 300px;
		background-size: cover;
		background-position: center;
	}
</style>
